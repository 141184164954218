import React from "react"

const Slider = () => {
    return (
        <div className="slider">
            <div className="slider__slide">
                <div className="slider__slide__content">
                    Od 1 lipca 2022 zapraszamy do skorzystania z <a href="/projekty-unijne/wypozyczalnia/">wypożyczalni</a> sprzętu rehabilitacyjnego, pielęgnacyjnego, wspomagającego dla mieszkańców powiatu krośnieńskiego i jasielskiego w Dukli.
                </div>
            </div>
            <div className="slider__slide">
                <div className="slider__slide__content">
                    Od 01.09.2022 roku zapraszamy do komercyjnego korzystania z <a href="/ddom/">Dziennego Domu Opieki Medycznej</a> im. Św. Jana w Dukli
                </div>
            </div>
            <div className="slider__slide">
                <div className="slider__slide__content">
                  Od 1 marca 2023 Poszerzamy zakres świadczonych usług o <a href="/aktualnosci/rehabilitacja-ogolnoustrojowa/">Rehabilitację Ogólnoustrojową w Ośrodku Dziennym </a>
                </div>
            </div>
        </div>
    )
}

export default Slider
